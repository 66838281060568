<template>
  <div>
    <div class="loader-wrapper" v-if="loader">
      <div class="image-loader"></div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Restaurants List'">
          <template v-slot:preview>
            <div>
              <div v-show="isAllow" class="form-group">
                <router-link to="add_restaurant" class="btn custom-btn-style">
                  Add Restaurant
                </router-link>
              </div>
              <div class="row align-items-center mb-3">
                <!-- Search -->
                <div class="col-sm-12 col-md-9">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right  d-block d-sm-block d-md-flex d-xl-flex d-md-flex"
                  >
                    <label class="d-inline-flex align-items-center mb-0">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <!--                    <div class="ml-2">-->
                    <!--                      <button-->
                    <!--                        class="btn print excel-btn"-->
                    <!--                        @click="excelExport()"-->
                    <!--                      >-->
                    <!--                        Export to Excel-->
                    <!--                      </button>-->
                    <!--                      <button class="btn print" @click="csvExport(csvData)">-->
                    <!--                        Export to CSV-->
                    <!--                      </button>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
              <!-- End search -->
              <div class="card">
                <div class="card-body p-0">
                  <div class="row mb-md-2"></div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      show-empty
                      :items="restaurants"
                      :fields="fields"
                      :per-page="0"
                      :current-page="currentPage"
                      responsive="true"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(avatar_cover)="restaurants">
                        <img
                          v-if="restaurants.item.avatar_cover !== null"
                          :src="restaurants.item.avatar_cover"
                          style="width: 50px; height: 50px"
                          @error="imageLoaderError"
                        />
                        <img
                          v-else
                          :src="'icontest.png'"
                          style="width: 50px; height: 50px"
                        />
                      </template>
                      <template v-slot:cell(avatar_src)="restaurants">
                        <img
                          v-if="restaurants.item.avatar_src !== null"
                          :src="restaurants.item.avatar_src"
                          style="width: 50px; height: 50px"
                          @error="imageLoaderError"
                        />
                        <img
                          v-else
                          :src="'icontest.png'"
                          style="width: 50px; height: 50px"
                        />
                      </template>
                      <template v-slot:cell(status.status)="restaurants">
                        <b-badge
                          v-if="restaurants.item.status.status === 'Suspended'"
                          class="mr-1 badge-font badge-warning"
                          pill
                          variant="primary"
                        >
                          {{ restaurants.item.status.status }}
                        </b-badge>
                        <b-badge
                          v-else
                          class="mr-1 badge-font badge-success"
                          pill
                          variant="primary"
                        >
                          {{ restaurants.item.status.status }}
                        </b-badge>
                      </template>
                      <template v-slot:cell(stripe_id)="restaurants">
                        <b-badge
                          v-if="restaurants.item.stripe_id === null"
                          class="mr-1 badge-font badge-danger"
                          pill
                          variant="danger"
                        >
                          Not Added
                        </b-badge>
                        <b-badge
                          v-else
                          class="mr-1 badge-font badge-success"
                          pill
                          variant="primary"
                        >
                          Added
                        </b-badge>
                      </template>
                      <template v-slot:cell(actions)="restaurants">
                        <div class="table-actions">
                          <a
                            v-if="permission.is_edit == 1"
                            href="javascript:void(0)"
                            class="success-icon"
                            @click="editRestaurant(restaurants)"
                            v-b-modal.modal-standard
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </a>
                          <a
                            v-if="permission.is_deleted == 1"
                            href="javascript:void(0)"
                            class="reject-icon"
                            @click="deleteRestaurant(restaurants)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </a>
                          <a
                            href="javascript:void(0)"
                            class="cog-icon"
                            @click="viewRestaurantSettings(restaurants)"
                            ><i class="fas fa-cog"></i
                          ></a>
                        </div>
                      </template>
                    </b-table>
                    <b-modal
                      id="modal-standard"
                      hide-footer
                      :title="'Edit ' + title + ' Records'"
                      modal-footer
                      title-class="font-18"
                      ref="modal"
                    >
                      <div>
                        <b-tabs content-class="mt-3">
                          <!-- End search -->
                          <form class="form">
                            <b-tab title="Restaurant Details" active>
                              <div class="card">
                                <div class="card-body p-0">
                                  <div class="row mb-md-2"></div>
                                  <!-- Table -->
                                  <div
                                    class="d-flex justify-content-between cover-img-wrapper p-3"
                                  >
                                    <div class="avatar-upload">
                                      <div class="avatar-edit">
                                        <input
                                          type="file"
                                          ref="myFile"
                                          id="coverImageUpload"
                                          accept=".png, .jpg, .jpeg"
                                          @change="onCoverFileChange"
                                        />
                                        <label for="coverImageUpload">
                                          <i class="fa fa-pen"></i>
                                        </label>
                                      </div>
                                      <div class="avatar-preview">
                                        <div
                                          id="coverImagePreview"
                                          :style="
                                            'background-image: url(' +
                                              avatar_cover +
                                              ')'
                                          "
                                        ></div>
                                      </div>
                                      <span>Cover</span>
                                    </div>
                                    <div class="avatar-upload">
                                      <div class="avatar-edit">
                                        <input
                                          type="file"
                                          id="imageUpload"
                                          accept=".png, .jpg, .jpeg"
                                          @change="onFileChange"
                                        />
                                        <label for="imageUpload">
                                          <i class="fa fa-pen"></i>
                                        </label>
                                      </div>
                                      <div class="avatar-preview">
                                        <div
                                          id="imagePreview"
                                          :style="
                                            'background-image: url(' +
                                              avatar_src +
                                              ')'
                                          "
                                        ></div>
                                      </div>
                                      <span>Logo</span>
                                    </div>
                                  </div>
                                  <span
                                    v-if="imageError"
                                    class="imageErrorClass"
                                    >{{ imageError }}</span
                                  >
                                  <hr />
                                  <div class="p-3">
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Restaurant</label
                                      >
                                      <input type="text" v-model="title" />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Slogan</label
                                      >
                                      <input type="text" v-model="tagline" />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Status</label
                                      >
                                      <select class="" v-model="status">
                                        <option value="" selected disabled
                                          >Select Status
                                        </option>
                                        <option value="Active">Active</option>
                                        <option value="Suspended"
                                          >Suspended</option
                                        >
                                      </select>
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Web Url</label
                                      >
                                      <input type="text" v-model="website" />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label
                                        class="restaurant-edit-field-label"
                                      >
                                        Contact
                                      </label>
                                      <input
                                        type="text"
                                        v-model="contact_number"
                                      />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label
                                        class="restaurant-edit-field-label"
                                      >
                                        Description
                                      </label>
                                      <textarea cols="40" v-model="description">
                                      </textarea>
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label
                                        class="restaurant-edit-field-label"
                                      >
                                        Date Added
                                      </label>
                                      <input
                                        type="text"
                                        readonly
                                        v-model="created_at"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-tab>
                            <b-tab title="Restaurant Address">
                              <div class="card">
                                <div class="card-body p-0">
                                  <div class="row mb-md-2"></div>
                                  <div class="p-3">
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Address Line 1</label
                                      >
                                      <input type="text" v-model="address_1" />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Address Line 2</label
                                      >
                                      <input type="text" v-model="address_2" />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Town</label
                                      >
                                      <input type="text" v-model="town" />
                                    </div>
                                    <div class="d-flex restaurant-edit-field">
                                      <label class="restaurant-edit-field-label"
                                        >Postal Code</label
                                      >
                                      <input type="text" v-model="postcode" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-tab>
                          </form>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-primary popup-btn"
                              @click="updateRestaurant"
                            >
                              Update
                            </button>
                          </div>
                        </b-tabs>
                      </div>
                    </b-modal>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalItems"
                        :per-page="perPage"
                      >
                      </b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import XLSX from "xlsx";
import helpers from "../../helpers";
import axios from "axios";

export default {
  data() {
    return {
      permission: {
        is_add: 1,
        is_deleted: 1,
        is_edit: 1,
        web_access_enabled: 1
      },
      image: {
        size: "",
        height: "",
        width: ""
      },
      MAX_SIZE: 100000,
      MAX_WIDTH: 375,
      MAX_HEIGHT: 200,
      imageError: "",
      loader: false,
      search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      totalItems: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      // sortBy: "title",
      sortDesc: true,
      DropDownMenu: false,
      uuid: "",
      dropDownListCheck: false,
      restaurants: [],
      orders: [],
      restaurant_uuid: "",
      fields: [
        { key: "title", sortable: true },
        { key: "contact_number", sortable: true },
        { key: "avatar_cover", label: "Cover", sortable: true },
        { key: "avatar_src", label: "Image", sortable: true },
        { key: "status.status", label: "Status", sortable: true },
        { key: "stripe_id", label: "Merchant Account", sortable: true },
        { key: "actions", sortable: true }
      ],
      editRestaurantObj: {},
      address_1: "",
      address_2: "",
      town: "",
      postcode: "",
      title: "",
      tagline: "",
      status: "",
      website: "",
      avatar_cover: "",
      cover_url: "",
      avatar_src: "",
      avatar_url: "",
      contact_number: "",
      description: "",
      created_at: "",
      isAllow: true
    };
  },
  components: {
    KTCodePreview
  },
  computed: {
    rows() {
      return this.restaurants.length;
    },
    csvData() {
      return this.restaurants.map(item => ({
        // ...item,
        title: item.title,
        contact_number: item.contact_number,
        status: item.status.status
      }));
    },
    isFormValid() {
      return !(
        this.title !== "" &&
        this.tagline &&
        this.contact_number &&
        this.address_1 &&
        this.address_2 &&
        this.town &&
        this.postcode
      );
    },
    isValid() {
      return this.imageError !== "";
    }
  },
  mounted() {
    document.addEventListener("click", this.close);
    if (
      JSON.parse(localStorage.getItem("currentUser")).roles[0].title ===
      "Business Owner"
    ) {
      this.isAllow = false;
      this.restaurantData = JSON.parse(localStorage.getItem("currentUser"));
      if (this.restaurantData.restaurant) {
        this.restaurant_uuid = this.restaurantData.restaurant.uuid;
      }
    } else {
      this.isAllow = true;
    }
    // let permission = JSON.parse(localStorage.getItem("currentUser")).permission;
    // this.permission = {
    //   is_add: permission.is_add,
    //   is_deleted: permission.is_deleted,
    //   is_edit: permission.is_edit,
    //   web_access_enabled: permission.web_access_enabled
    // };
    this.getAllRestaurants();
    this.totalRows = this.restaurants.length;
  },
  methods: {
    imageLoaderError(event) {
      return (event.target.src = "icontest.png");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getAllRestaurants() {
      let obj = {
        uuid: this.restaurant_uuid
      };
      await axios
        .get(
          this.$path + "/admin/restaurant?page=" + this.currentPage + "&" + obj
        )
        .then(res => {
          this.totalItems = parseInt(res.data.meta.total, 10);
          this.restaurants = res.data.data;
          this.loader = false;
          return res.data.data;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },

    /* getAllRestaurants() {
      this.loader = true;
      let obj = {
        uuid: this.restaurant_uuid
      };
      ApiService.get(
        this.$path + "/admin/restaurant?page=" + this.currentPage,
        obj
      )
        .then(response => {
          console.log("total", response.data.meta.total);
          this.totalItems = response.data.meta.total;
          this.restaurants = response.data.data;
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },*/
    viewRestaurantSettings(restaurant) {
      localStorage.setItem(
        "restaurantDetails",
        JSON.stringify(restaurant.item)
      );
      this.$router.push({ name: "restaurant_setting" });
    },
    clickDots(uuid) {
      this.uuid = uuid;
      if (this.DropDownMenu) {
        this.DropDownMenu = false;
      } else {
        this.DropDownMenu = true;
      }
    },
    deleteRestaurant(restaurant) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.isConfirmed) {
          this.loader = true;
          ApiService.delete(
            this.$path + "/admin/restaurant/" + restaurant.item.uuid
          )
            .then(response => {
              helpers.successMessages(this, response.data.message);
              this.loader = false;
              let index = this.restaurants.findIndex(
                el => el.uuid === response.data.data.uuid
              );
              if (index !== -1) {
                this.restaurants.splice(index, 1);
              }
            })
            .catch(err => {
              this.loader = false;
              if (err.response.data.status === 400) {
                helpers.errorOther(this, err.response.data);
              }
              if (err.response.data.status === 422) {
                helpers.validationError(this, err.response.data.errors);
              }
              if (err.response.status === 404) {
                helpers.errorMessage(this, "url not found");
              }
              if (err.response.status === 500) {
                helpers.serverError(this);
              }
            });
        }
      });
    },
    editRestaurant(obj) {
      this.restaurant_uuid = obj.item.uuid;
      this.address_1 = obj.item.location.address_1;
      this.address_2 = obj.item.location.address_2;
      this.town = obj.item.location.city;
      this.postcode = obj.item.location.postcode;
      this.title = obj.item.title;
      this.tagline = obj.item.tagline;
      this.status = obj.item.status.status;
      this.website = obj.item.website;
      this.contact_number = obj.item.contact_number;
      this.description = obj.item.description;
      this.created_at = obj.item.created_at;
      this.avatar_cover = obj.item.avatar_cover;
      this.avatar_src = obj.item.avatar_src;
    },
    updateRestaurant() {
      this.loader = true;
      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("tagline", this.tagline);
      formData.append("status", this.status);
      formData.append("website", this.website);
      formData.append("contact_number", this.contact_number);
      formData.append("description", this.description);
      formData.append("avatar_cover", this.avatar_cover);
      formData.append("avatar_src", this.avatar_src);
      formData.append("address_1", this.address_1);
      formData.append("address_2", this.address_2);
      formData.append("town", this.town);
      formData.append("postcode", this.postcode);

      ApiService.post(
        this.$path + "/admin/restaurant/address/" + this.restaurant_uuid,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
        .then(response => {
          let index = this.restaurants.findIndex(
            el => el.uuid === response.data.data.uuid
          );
          if (index !== -1) {
            this.restaurants[index].status = response.data.data.status;
            this.restaurants[index].location.address_1 =
              response.data.data.location.address_1;
            this.restaurants[index].location.address_2 =
              response.data.data.location.address_2;
            this.restaurants[index].location.city =
              response.data.data.location.city;
            this.restaurants[index].location.postcode =
              response.data.data.location.postcode;
            this.restaurants[index].title = response.data.data.title;
            this.restaurants[index].tagline = response.data.data.tagline;
            this.restaurants[index].website = response.data.data.website;
            this.restaurants[index].contact_number =
              response.data.data.contact_number;
            this.restaurants[index].description =
              response.data.data.description;
            this.restaurants[index].created_at = response.data.data.created_at;
            this.restaurants[index].avatar_cover =
              response.data.data.avatar_cover;
            this.restaurants[index].avatar_src = response.data.data.avatar_src;
          }
          helpers.successMessages(this, response.data.message);
          this.$bvModal.hide("modal-standard");
          this.loader = false;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "Restaurants.csv");
      link.click();
    },
    excelExport() {
      const data = XLSX.utils.json_to_sheet(this.restaurants);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Restaurants.xlsx");
    },
    closeMenu() {
      this.DropDownMenu = false;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.imageError = "";
        this.closeMenu();
      }
    },
    onCoverFileChange(e) {
      const file = e.target.files[0];
      // this.imageError = "";
      // if (!file || file.type.indexOf("image/") !== 0) return;
      // this.image.size = file.size;
      // if (this.image.size > this.MAX_SIZE) {
      //   this.imageError = `The image size is (${this.image.size}) (max is ${this.MAX_SIZE}).`;
      //   return;
      // }
      // let reader = new FileReader();
      // reader.readAsDataURL(file);
      // reader.onload = evt => {
      //   let img = new Image();
      //   img.onload = () => {
      //     this.image.width = img.width;
      //     this.image.height = img.height;
      //     if (this.image.width > this.MAX_WIDTH) {
      //       this.imageError = `The image width is (${this.image.width}) (max is ${this.MAX_WIDTH}).`;
      //       return;
      //     }
      //     if (this.image.height > this.MAX_HEIGHT) {
      //       this.imageError = `The image height is (${this.image.height}) (max is ${this.MAX_HEIGHT}).`;
      //       return;
      //     }
      //   };
      //   img.src = evt.target.result;
      this.avatar_cover = file;
      this.cover_url = URL.createObjectURL(file);
      $("#coverImagePreview").css(
        "background-image",
        "url(" + this.cover_url + ")"
      );
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.avatar_src = file;
      this.avatar_url = URL.createObjectURL(file);
      $("#imagePreview").css(
        "background-image",
        "url(" + this.avatar_url + ")"
      );
    }
  },
  watch: {
    currentPage: {
      handler: function() {
        // eslint-disable-next-line no-unused-vars
        this.getAllRestaurants().catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.close);
  }
};
</script>
<style scoped>
.avatar-preview > div {
  border-radius: 0;
}

.card.card-custom > .card-body {
  padding: 2rem 1.25rem;
}

.cover-img-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cover-img-wrapper .profile-img {
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  margin-right: 10px;
}

.cover-img-wrapper .cover-img {
  flex: 0 0 calc(50% - 10px);
  width: calc(50% - 10px);
  margin-left: 10px;
}

.cover-img-wrapper img {
  width: 100%;
}

.restaurant-edit-field {
  border: 1px solid #ddd;
  border-bottom: 0;
}

.restaurant-edit-field:last-child {
  border-bottom: 1px solid #dddddd;
}

.restaurant-edit-field-label {
  margin: 0;
  width: 150px;
  background-color: #fd683e;
  color: #fff;
  padding: 5px 10px;
  text-align: right;
}

.restaurant-edit-field input,
.restaurant-edit-field textarea,
.restaurant-edit-field select {
  flex: 0 0 calc(100% - 150px);
  padding: 5px 10px;
}

.restaurant-edit-field textarea {
  height: 100px;
}

.restaurant-edit-field input:focus,
.restaurant-edit-field select {
  box-shadow: none;
  outline: none;
}

.restaurant-edit-field textarea:focus {
  box-shadow: none;
  outline: none;
}

.avatar-upload .avatar-preview {
  margin-bottom: 10px;
}

.imageErrorClass {
  color: red;
  position: relative;
  top: -20px;
  left: 20px;
  display: inline-block;
}

@media only screen and (max-width: 575px) {
  .cover-img-wrapper {
    display: block !important;
  }

  .avatar-upload .avatar-preview {
    width: 205px;
    height: 205px;
  }
}
</style>
